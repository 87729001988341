@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jersey+15&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Libre+Franklin:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Signika+Negative:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

@layer utilities {
  .content-div {
    @apply flex flex-col w-full h-full transition-all duration-75;
  }

  .content-div-open {
    @apply md:ml-72;
  }

  .content-div-closed {
    @apply md:ml-24;
  }

  .dashboard-nav-link {
    @apply flex items-center gap-3 p-3 mt-1 ring-slate-50 rounded-lg transition-all duration-100;
  }

  .dashboard-nav-link:hover {
    @apply bg-[#3A3A3F] ring-1;
  }

  .dashboard-nav-link-closed {
    @apply justify-center;
  }

  .admin-nav-link {
    @apply ring-slate-50 rounded-lg transition-all duration-100;
  }

  .admin-nav-link[aria-current="page"] p {
    @apply text-indigo-200;
  }

  .admin-nav-link:hover {
    @apply bg-[#3A3A3F] ring-1;
  }

  .rotated-image-right {
    transform: perspective(1000px) rotateY(10deg);
    transform-origin: center;
  }

  .rotated-image-left {
    transform: perspective(1000px) rotateY(-10deg);
    transform-origin: center;
  }

  .absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .no-slider {
    appearance: textfield;
  }

  .no-slider::-webkit-outer-spin-button,
  .no-slider::-webkit-inner-spin-button {
    appearance: none;
  }

  .scrollbar-thin {
    scrollbar-width: thin;
    scrollbar-color: #565665 #2a2a2f00;
    /* Customize colors */
  }

  .scrollbar-thin::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .scrollbar-thin::-webkit-scrollbar-thumb {
    background-color: #565665;
    /* Customize thumb color */
    border-radius: 4px;
  }

  .scrollbar-thin::-webkit-scrollbar-track {
    background-color: #2a2a2f00;
    /* Customize track color */
  }

}

.rich-text-content {
  /* General styles for the container */
  font-size: 1rem;
  @apply text-text-secondary-dark;
}

/* Headings */
.rich-text-content h1 {
  @apply text-4xl font-semibold text-text-primary mt-6 mb-4;
}

.rich-text-content h2 {
  @apply text-3xl font-semibold text-text-primary mt-5 mb-3;
}

.rich-text-content h3 {
  @apply text-2xl font-semibold text-text-primary mt-4 mb-2;
}

.rich-text-content h4 {
  @apply text-xl font-semibold text-text-primary mt-3 mb-2;
}

.rich-text-content h5 {
  @apply text-lg font-semibold text-text-primary mt-2 mb-1;
}

.rich-text-content h6 {
  @apply text-base font-semibold text-text-primary mt-2 mb-1;
}

.rich-text-content strong {
  @apply font-semibold;
}

/* Lists */
.rich-text-content ul {
  @apply list-disc list-inside mt-2 mb-2;
}

.rich-text-content ol {
  @apply list-decimal list-inside mt-2 mb-2;
}

.rich-text-content li {
  @apply mb-1;
}

/* Images */
.rich-text-content img {
  @apply max-w-full h-auto mt-4 mb-4;
}

/* Blockquotes */
.rich-text-content blockquote {
  @apply border-l-4 border-gray-300 pl-4 italic text-gray-600 mt-4 mb-4;
}

/* Code blocks */
.rich-text-content pre {
  @apply bg-gray-100 p-4 rounded mt-4 mb-4 overflow-x-auto;
}

.rich-text-content code {
  @apply bg-gray-100 p-1 rounded;
}

.rich-text-content a {
  @apply text-blue-500 underline;
}

/* Tooltips */
.tooltip {
  @apply bg-white border-2 text-text-primary p-2 rounded shadow-lg;
}

.tooltip-arrow {
  @apply bg-gray-800;
}

@media print {
  .no-print {
    display: none;
  }
}



body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}